<template>
    <div class="bg-w">
        <div class="pd30">
            <div class="flex end">
                <a-form layout="inline">
                    <a-form-item label="关键字">
                        <a-input v-model="keyword" placeholder="请输入关键字"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="searchAct" type="primary">搜索</a-button>
                    </a-form-item>
                </a-form>
            </div>
            <div class="mt20">
                <div class="wxb-table-gray">
                    <a-table rowKey="firm_id" :columns="columns" :pagination="pagination" @change="change"
                        :data-source="list" :loading="loading">
                        <template slot="parent_id" slot-scope="record,index">
                            <a-tag color="purple" v-if="record.type > 1">自配</a-tag>
                            <a-tag color="cyan" v-else>平台</a-tag>
                        </template>
                        <template slot="type" slot-scope="record,index">
                            <a-tag color="#2db7f5" v-if="record.type == 1">企业版</a-tag>
                            <a-tag color="#87d068" v-else>标准版</a-tag>
                            / {{ record.staff_num }}
                        </template>
                        <template slot="kefu" slot-scope="record,index">
                            <a-tag color="red">{{ record.kefu.name }}</a-tag>
                        </template>
                        <template slot="action" slot-scope="record,index">
                            <a-button type="link" @click="kefuEdit(record)" style="color:#f50">客服</a-button>
                            <a-button type="link" @click="toLogin(record)">管理</a-button>
                        </template>
                    </a-table>
                </div>
            </div>
        </div>
        <a-modal width="400px" title="客服" :visible="show" @ok="kefuSave" @cancel="show=false">
            <a-form-model :model="data">
                <a-form-model-item label="客服">
                    <a-select v-model="data.customer_service_id" style="width: 200px;">
                        <a-select-option v-for="(row,index) in customerServiceList" :key="index" :value="row.customer_service_id">{{row.name}}</a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>

</template>

<script>
import { apiFirmList } from '../../utils/api/account.js';
import { apiLoginAdmin } from '../../utils/js/api.js';
import { apiCustomerServiceList, apiCustomerServiceEdit } from '../../utils/api/account';
export default {
    data() {
        return {
            show:false,
            data:{},
            customerServiceList:[],
            list: [],
            keyword: '',
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'firm_id',
                    align: 'center',
                    ellipsis: true
                },
                {
                    title: '名称',
                    dataIndex: 'firm_name',
                    align: 'center',
                    ellipsis: true
                },
                { title: '小程序', key: 'parent_id', align: 'center', scopedSlots: { customRender: 'parent_id' } },
                
                { title: '类型/人数', key: 'type', align: 'center', scopedSlots: { customRender: 'type' } },
                { title: '客服', key: 'kefu', align: 'center', scopedSlots: { customRender: 'kefu' } },
                {
                    title: '创建时间',
                    dataIndex: 'create_time',
                    align: 'center',
                    ellipsis: true
                },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } }
            ],
            datas: [],
        }
    },
    created() {
        this.getLists();
        apiCustomerServiceList().then(res=>{
            this.customerServiceList = res.list
        })
    },
    methods: {
        getLists() {
            if (this.loading == true) return;
            this.loading = true;
            apiFirmList({
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                keyword: this.keyword,
            }).then(res => {
                this.pagination.total = res.total;
                this.list = res.list;
                this.loading = false;
            }).catch(res => {
                this.loading = false;
            })
        },
        searchAct() {
            this.pagination.current = 1;
            this.getLists();
        },
        outLogin() {
            localStorage.removeItem('xhmmp-account-token');
            this.$router.push('/login');
        },

        change(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
        toLogin(record) {
            apiLoginAdmin({
                firm_id: record.firm_id
            }).then(res => {
                console.log('res', res)
                window.open(res.url);
            })
        },
        kefuEdit(detail){
            this.data = detail;
            this.show = true;
        },
        kefuSave(){
            console.log('data',this.data);
            apiCustomerServiceEdit(this.data).then(res=>{
                console.log('firm-kefu-res',res)
                this.$message.success('修改成功');
                this.show = false;
                this.getLists();
            })
        }
    }
}
</script>

<style>
.content {
    max-width: 1200px;
    margin: 0 auto;
}
</style>