import { api } from '../js/request.js'

//账号列表
export function apiFirmList(data){
    return api('custom/firmList',data);
}
//客服列表
export function apiCustomerServiceList(data){
    return api('custom/customerServiceList',data);
}
//客服添加/修改
export function apiCustomerServiceSave(data){
    return api('custom/customerServiceSave',data);
}

//修改公司客服
export function apiCustomerServiceEdit(data){
    return api('custom/customerServiceEdit',data);
}